import React, { Component } from 'react';
import dynamic              from 'next/dynamic';

// dynamic imports
const Layout = dynamic(() => import('../containers/Layout'));

/**
 * HOC for all pages except authentication routes like login route.
 *  This is where we have placed our top bar and menus
 */

const withLayout = (PageComponent, showAds = false) => {
  return class LayoutWrapped extends Component {
    render() {
      return (
        <Layout {...this.props} showAds={showAds}>
          <PageComponent {...this.props} {...this.state} />
        </Layout>
      );
    }
  };
};

export default withLayout;
